.NavBar {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: #0d0d0d;
  width: 10vw;
  height: 100vh;
  border: solid;
  border-color: rgba(218,224,17,0.2);
  border-top: 0px;
  border-bottom: 0px;
  border-left: 0px;
  border-right-width: 2px;
}

.SpacesLogo{
  display: flex;
  text-align: center;
  flex-direction: column;
  align-self: center;
  margin-top: 40px;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 15px;
}

.NavBarButton {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-self: center;
  margin-top: 40px;
  justify-content: center;
  background-color: transparent;
  border-color:transparent;
  border-width: 0px;
  width: 50px;
  height: 50px;
  border-radius: 15px;
}