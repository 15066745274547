.CampaignFinal {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    height: 100%;
}


/* CombinedList Styles */
.asset-item-scroll-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    align-self: start;
    padding-right: 8px;
    margin-top: 20px;
}

/* Customize the scrollbar (for webkit browsers) */
.asset-item-scroll-container::-webkit-scrollbar {
    width: 12px;
}

.asset-item-scroll-container::-webkit-scrollbar-thumb {
    background-color: rgba(218,224,17,1);
    border-radius: 6px;
    cursor: pointer;
}

.asset-item-scroll-container::-webkit-scrollbar-thumb:hover {
    background-color: rgba(218,224,17,0.5);
}

.asset-item-scroll-container::-webkit-scrollbar-track {
    background-color: #1a1a1a;
    border-radius: 6px;
}

/* ListItem Styles */
.asset-metric-list-item {
    display: flex;
    width: 100%;
    background-color: #1a1a1a;
    border: none;
    border-radius: 20px;
    padding: 0;
    text-align: left;
    transition: background-color 0.3s, color 0.3s;
    color:white;
    margin-bottom:20px;
}

/* Customize the scrollbar (for webkit browsers) */
.asset-item-scroll-container::-webkit-scrollbar {
    width: 12px;
}

.asset-item-scroll-container::-webkit-scrollbar-thumb {
    background-color: rgba(218,224,17,1);
    border-radius: 6px;
    cursor: pointer;
}

.asset-item-scroll-container::-webkit-scrollbar-thumb:hover {
    background-color: rgba(218,224,17,0.5);
}

.asset-item-scroll-container::-webkit-scrollbar-track {
    background-color: #1a1a1a;
    border-radius: 6px;
}



/* CampaignNameInput Styles */
.campaign-name-input {
    display: flex;
    flex-direction: column;
    /* background-color: red; */
    width: 400px;
    height: 110px;
    justify-content: center;
}

.campaign-name-input input {
    display: flex;
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 16px;
    background-color: #1a1a1a;
    color: #F6F0ED;
    font-weight: bold;
    font-size: 22px;
    font-family: Comfortaa;
}

.campaign-name-input input::placeholder {
   color: rgba(255, 255, 255, 0.3);
}

.campaign-name-input input:focus {
    outline: none;
}



.status-input {
    display: flex;
    flex-direction: column;
    /* background-color: red; */
    width: 200px;
    height: 110px;
    justify-content: center;
}

.status-input input {
    display: flex;
    width: 100%;
    height: 50px;
    border: none;
    text-align: center;
    border-radius: 16px;
    background-color: #1a1a1a;
    color: #F6F0ED;
    font-weight: bold;
    font-size: 22px;
    font-family: Comfortaa;
}

.status-input input::placeholder {
   color: rgba(255, 255, 255, 0.3);
}

.status-input input:focus {
    outline: none;
}

/* CampaignDurationSelection Styles */
/* CampaignDurationSelection Styles */
.campaign-duration-selection {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-left:5px;
    margin-top:10px;
}

.duration-options {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 500px;
}

.duration-option {
    display: flex;
    background-color: #1a1a1a;
    color: #F6F0ED;
    border: none;
    border-radius: 16px;
    padding: 10px;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    font-family: Comfortaa;
    cursor: pointer;
    width: 30%;
    text-align: center;
    transition: background-color 0.3s, color 0.3s;
}

.duration-option.selected {
    background-color: #DAE011;
    color: #0d0d0d;
}

.duration-option:hover {
    background-color: #0d0d0d;
    color: #DAE011;
}

.user-metrics-button {
    display: flex;
    width: 120px;
    background-color: #1a1a1a;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 22px;
    font-weight: bold;
    font-family: Comfortaa;
    justify-content:center;
    cursor: pointer;
    margin-top: 10px;
    margin-right: 50px;
    transition: background-color 0.3s, color 0.3s;
}

.back-metrics-button {
    display: flex;
    width: 120px;
    background-color: #1a1a1a;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 22px;
    font-weight: bold;
    font-family: Comfortaa;
    justify-content:center;
    cursor: pointer;
    margin-top: 20px;
    margin-right: 50px;
    transition: background-color 0.3s, color 0.3s;
}



.assets-metrics-button {
    display: flex;
    width: 120px;
    background-color: #1a1a1a;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 22px;
    font-weight: bold;
    font-family: Comfortaa;
    justify-content:center;
    cursor: pointer;
    margin-top: 10px;
    margin-right: 50px;
    transition: background-color 0.3s, color 0.3s;
}

.rewards-metrics-button {
    display: flex;
    width: 120px;
    background-color: #1a1a1a;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 22px;
    font-weight: bold;
    font-family: Comfortaa;
    justify-content:center;
    cursor: pointer;
    margin-top: 10px;
    margin-right: 50px;
    transition: background-color 0.3s, color 0.3s;
}

.reload-button {
    display: flex;
    width: 50px;
    height: 50px;
    background-color: #1a1a1a;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 22px;
    font-weight: bold;
    font-family: Comfortaa;
    justify-content:center;
    cursor: pointer;
    margin-top: 10px;
    margin-right: 50px;
    transition: background-color 0.3s, color 0.3s;
}

