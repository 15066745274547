.LinkAssetsCampaign {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    height: 100%;
}


/* CombinedList Styles */
.scroll-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    align-self: start;
    padding-right: 8px;
}

/* Customize the scrollbar (for webkit browsers) */
.scroll-container::-webkit-scrollbar {
    width: 12px;
}

.scroll-container::-webkit-scrollbar-thumb {
    background-color: rgba(218,224,17,1);
    border-radius: 6px;
    cursor: pointer;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
    background-color: rgba(218,224,17,0.5);
}

.scroll-container::-webkit-scrollbar-track {
    background-color: #1a1a1a;
    border-radius: 6px;
}

.scroll-reward-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    align-self: start;
    padding-right: 8px;
    margin-top:20px;
}

/* Customize the scrollbar (for webkit browsers) */
.scroll-reward-container::-webkit-scrollbar {
    width: 12px;
}

.scroll-reward-container::-webkit-scrollbar-thumb {
    background-color: rgba(218,224,17,1);
    border-radius: 6px;
    cursor: pointer;
}

.scroll-reward-container::-webkit-scrollbar-thumb:hover {
    background-color: rgba(218,224,17,0.5);
}

.scroll-reward-container::-webkit-scrollbar-track {
    background-color: #1a1a1a;
    border-radius: 6px;
}



/* ListItem Styles */
.list-item {
    display: flex;
    width: 100%;
    background-color: #1a1a1a;
    border: none;
    border-radius: 20px;
    padding: 0;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.list-reward-item {
    display: flex;
    height: 70px;
    background-color: #1a1a1a;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 5px;
    border-bottom: 0px solid;
    border-radius:20px;
    border-color: transparent;
}


.list-item.selected {
    background-color: #DAE011;
    color: #0d0d0d;
}

.list-item:hover {
    background-color: #0d0d0d;
    color: white;
}

.list-item-content {
    display: flex;
    flex-direction: row;
    width: 96%;
    height: 80%;
    align-self: center;
    justify-content: space-between;
}

.list-item-content button {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    width: 30px;
    height: 30px;
    background-color: #1a1a1a;
    border: 2px solid #1a1a1a;
    border-radius: 50%;
    cursor: pointer;
}

.list-item-content button:hover {
    background-color: #DAE011;
    color:white;
    border-color: #DAE011;
}

.list-item-content button:focus {
    outline: none;
}




/* CampaignNameInput Styles */
.reward-code-input {
    display: flex;
    width: 200px;
    height: 50px;
    margin-top: 40px;
    justify-content: center;
    margin-left: 5px;
    margin-right: 50px;
}

.reward-code-input input {
    display: flex;
    width: 100%;
    height: 50px;
    padding-left: 20px;
    border: none;
    border-radius: 16px;
    background-color: #1a1a1a;
    color: #F6F0ED;
    font-weight: bold;
    font-size: 22px;
    font-family: Comfortaa;
}

.reward-code-input input::placeholder {
   color: rgba(255, 255, 255, 0.3);
}

.reward-code-input input:focus {
    outline: none;
}

.reward-description-input {
    display: flex;
    width: 700px;
    height: 50px;
    margin-top: 40px;
    flex-direction: row;
    justify-content: center;
    margin-left: 5px;
}

.reward-description-input input {
    display: flex;
    width: 100%;
    height: 50px;
    padding-left: 20px;
    border: none;
    border-radius: 16px;
    background-color: #1a1a1a;
    color: #F6F0ED;
    font-weight: bold;
    font-size: 22px;
    font-family: Comfortaa;
}

.reward-description-input input::placeholder {
   color: rgba(255, 255, 255, 0.3);
}

.reward-description-input input:focus {
    outline: none;
}

.character-limit-message {
    color: #FF5252;
    font-size: 14px;
    margin-top: 5px;
}


.reward-unlock-count-input {
    display: flex;
    width: 200px;
    height: 50px;
    margin-top: 40px;
    justify-content: center;
    margin-left: 5px;
    margin-right: 50px;
}

.reward-unlock-count-input input {
    display: flex;
    width: 100%;
    height: 50px;
    padding-left: 20px;
    border: none;
    border-radius: 16px;
    background-color: #1a1a1a;
    color: #F6F0ED;
    font-weight: bold;
    font-size: 22px;
    font-family: Comfortaa;
}

.reward-unlock-count-input input::placeholder {
   color: rgba(255, 255, 255, 0.3);
}

.reward-unlock-count-input input:focus {
    outline: none;
}



.reward-claim-count-input {
    display: flex;
    width: 250px;
    height: 50px;
    margin-top: 40px;
    justify-content: center;
    margin-left: 5px;
    margin-right: 50px;
}

.reward-claim-count-input input {
    display: flex;
    width: 100%;
    height: 50px;
    padding-left: 20px;
    border: none;
    border-radius: 16px;
    background-color: #1a1a1a;
    color: #F6F0ED;
    font-weight: bold;
    font-size: 22px;
    font-family: Comfortaa;
}

.reward-claim-count-input input::placeholder {
   color: rgba(255, 255, 255, 0.3);
}

.reward-claim-count-input input:focus {
    outline: none;
}


.create-reward-button {
    display: flex;
    width: 200px;
    background-color: #1a1a1a;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 22px;
    font-weight: bold;
    font-family: Comfortaa;
    margin-top: 30px;
    align-self: center;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.create-reward-button:disabled {
    background-color: #808080; /* Gray out the button when disabled */
    cursor: not-allowed;
}

.create-reward-button:hover {
    background-color:  #DAE011;
    color:#1a1a1a;
}


.add-reward-button {
    display: flex;
    width: 220px;
    background-color: #1a1a1a;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 22px;
    font-weight: bold;
    font-family: Comfortaa;
    justify-content:center;
    cursor: pointer;
    margin-top: 50px;
    margin-left: 5px;
    transition: background-color 0.3s, color 0.3s;
}


.add-reward-button:disabled {
    background-color: #808080; /* Gray out the button when disabled */
    cursor: not-allowed;
}

.add-reward-button:hover {
    background-color:  #DAE011;
    color:#1a1a1a;
}


.next-button {
    display: flex;
    width: 120px;
    background-color: #1a1a1a;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 22px;
    font-weight: bold;
    font-family: Comfortaa;
    justify-content:center;
    cursor: pointer;
    margin-top: 30px;
    margin-left: 5px;
    transition: background-color 0.3s, color 0.3s;
}


.next-button:disabled {
    background-color: #808080; /* Gray out the button when disabled */
    cursor: not-allowed;
}

.next-button:hover {
    background-color:  #DAE011;
    color:#1a1a1a;
}
