.ListCampaigns {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    height: 100%;
}
.scroll-container {
    width: 100%;           /* Change this to your desired width */
    height: 100%;          /* Change this to your desired height */
    overflow-y: auto;       /* Enable vertical scroll */
    align-self: start;
    padding-right: 8px;
}

.list-item {
    display: flex;
    height: 70px;
    background-color: #1a1a1a;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 5px;
    border-bottom: 0px solid;
    border-radius:20px;
    border-color: transparent;
}

/* Customize the scrollbar (for webkit browsers) */
.scroll-container::-webkit-scrollbar {
    width: 12px;
}

.scroll-container::-webkit-scrollbar-thumb {
    background-color: rgba(218,224,17,1);
    border-radius: 6px;
    cursor: pointer;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
    background-color: rgba(218,224,17,0.5);
}

.scroll-container::-webkit-scrollbar-track {
    background-color: #1a1a1a;
    border-radius: 6px;
}

.campaign-duration-selection {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-left:5px;
    margin-top:10px;
}

.duration-options {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 500px;
}

.duration-option {
    display: flex;
    background-color: #1a1a1a;
    color: #F6F0ED;
    border: none;
    border-radius: 16px;
    padding: 10px;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    font-family: Comfortaa;
    cursor: pointer;
    width: 30%;
    text-align: center;
    transition: background-color 0.3s, color 0.3s;
}

.duration-option.selected {
    background-color: #DAE011;
    color: #0d0d0d;
}

.duration-option:hover {
    background-color: #0d0d0d;
    color: #DAE011;
}


.next-button {
    display: flex;
    width: 120px;
    background-color: #1a1a1a;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 22px;
    font-weight: bold;
    font-family: Comfortaa;
    justify-content:center;
    cursor: pointer;
    margin-top: 50px;
    margin-left: 40px;
    transition: background-color 0.3s, color 0.3s;
}

.next-button:disabled {
    background-color: #808080; /* Gray out the button when disabled */
    cursor: not-allowed;
}

.next-button:hover {
    background-color:  #DAE011;
    color:#1a1a1a;
}

.create-campaign-button {
    display: flex;
    width: 300px;
    background-color: #1a1a1a;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 22px;
    font-weight: bold;
    font-family: Comfortaa;
    margin-top: 25px;
    justify-content: center;
    align-self: center;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.create-campaign-button:disabled {
    background-color: #808080; /* Gray out the button when disabled */
    cursor: not-allowed;
}

.create-campaign-button:hover {
    background-color:  #DAE011;
    color:#1a1a1a;
}