.App {
  display: flex;
  text-align: center;
  flex-direction: row;
  justify-content: start;
  background-color: #0d0d0d;
  width: 100vw;
  height: 100vh;
}

